export interface DashboardTheme {
    name: string;
    uid: string;
    layout: string;
}

const DashboardThemes: DashboardTheme[] = [
    {
        name: 'icCube default theme',
        uid: 'ic3-statos',
        layout: 'statos_grid_layout_split_50'
    },
    {
        name: 'Demo theme',
        uid: 'Demo',
        layout: 'Demo. Grid Layout'
    }
]

export default DashboardThemes;