import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import img from "../images/logo-demo-white.svg";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {Menu, MenuItem} from "@mui/material";
import {setIsLoggedIn} from "../store/ApplicationSlice";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import React from "react";
import useUser from "../hooks/useUser";
import {useDispatch} from "react-redux";
import {toggleSidebar} from "./sidebarUtils";
import Box from "@mui/material/Box";

interface Props {
    currentPage: string;
}

export default function Header({currentPage}: Props) {

    const user = useUser();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useDispatch();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <AppBar position="fixed"
                   sx={{zIndex: (theme) => theme.zIndex.drawer + 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
                   elevation={0}>
        <Toolbar sx={{gap: 1}} variant="dense">
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={() => toggleSidebar()}>
                <MenuIcon/>
            </IconButton>
            <Box width={"150px"} display={'flex'} alignItems={'center'} overflow={'hidden'} mt={'4px'}>
                <img height={"20px"} src={img} alt={""}/>
            </Box>
            <Typography variant="h6" component="div" sx={{
                flex: 1, overflow: 'hidden', textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>
                {currentPage}
            </Typography>
            <Typography variant={'body1'}>{user.email}</Typography>
            <div>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleMenu}
                >
                    <AccountCircle/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => dispatch(setIsLoggedIn(false))}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </Menu>
            </div>
        </Toolbar>
    </AppBar>
}