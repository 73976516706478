import React, {useCallback, useMemo, useState} from 'react';
import {Box} from "@mui/material";
import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import {IEventContent} from "@ic3/reporting-api-embedded/dist/IReporting";
import {EmbeddedDiv} from "./EmbeddedDiv";
import {DashboardsDivContext, DashboardsDivReactContext} from './DashboardsDivContext';
import {useAppSelector} from "../store/hooks";

interface Props {
    eventActions?: { name: string, callback: (value: IEventContent | null) => void }[];
    dashboardPath: string;
    // For print button
    setReportDef: (report: IReportDefinition | null) => void;
    // For print button
    setReporting: (ic3: IReporting) => void;
}

export default function ApplicationLoader(props: Props) {

    const context = useMemo(() => new DashboardsDivContext({
        customHeaders: "dashboards"
    }), []);

    const {eventActions, dashboardPath, setReportDef, setReporting} = props;
    const dashboardTheme = useAppSelector(state => state.application.dashboardTheme);
    const overlay = useAppSelector(state => state.application.overlay);

    // The icCube dashboards application as a IReporting instance.
    const [internalReporting, setInternalReporting] = useState<IReporting>();

    useMemo(() => {
        console.log("[ic3Div] fireEvent(selDash)", dashboardPath);
        if (internalReporting != null && dashboardPath != null) {
            internalReporting.openReport({
                path: dashboardPath,

                onDefinition: (report: IReportDefinition) => {
                    report.setThemeUID(dashboardTheme.uid);
                    // @ts-ignore
                    report.reportDef.layouts.desktop.layoutConfigId = dashboardTheme.layout;
                    setReportDef(report);
                },

                onError: () => {
                    setReportDef(null);
                    return true /* handled */;
                }

            });
        }
    }, [dashboardPath, internalReporting, setReportDef, dashboardTheme]);

    const ic3ready = useCallback((ic3: IReporting) => {

        console.log("[ic3Div] ic3ready : ", ic3);

        const appPath = "shared:/procure-app";

        console.log("[ic3Div] ic3ready : opening " + appPath);

        ic3.openReportApp({path: appPath});

        setInternalReporting(ic3);
        setReporting(ic3);


    }, [setReporting]);

    useMemo(() => {
        internalReporting && eventActions?.forEach(i => {
            internalReporting.onEvent(i.name, i.callback);
        })
    }, [eventActions, internalReporting]);

    return (
        <DashboardsDivReactContext.Provider value={context}>
            <Box display={'flex'} flexDirection={'column'} flex={1} overflow={'hidden'}
                 sx={{border: overlay ? '4px solid red' : undefined}}>
                <EmbeddedDiv onIc3ready={ic3ready} uid={"app"}/>
            </Box>
        </DashboardsDivReactContext.Provider>
    );

}
