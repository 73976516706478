import React, {useCallback, useMemo, useState} from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Collapse, ListSubheader} from '@mui/material';
import {AiDashboards, Dashboards, Pages} from "./Pages";
import {setDashboard, setIsLoggedIn, setPage} from "./store/ApplicationSlice";
import {useDispatch} from "react-redux";
import PageController from "./pages/PageController";
import {useAppSelector} from "./store/hooks";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import useCustomHeaders from "./hooks/useCustomHeaders";
import {IReportDefinition, IReporting} from "@ic3/reporting-api-embedded";
import RefreshIcon from '@mui/icons-material/Refresh';
import useUser from "./hooks/useUser";
import TopDashboardEditorBar from "./components/TopDashboardEditorBar";
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import PopupOrderDetail from "./pages/PopupOrderDetail";
import Header from "./components/Header";
import {closeSidebar} from "./components/sidebarUtils";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import DeleteDashboardMenu from "./components/DeleteDashboardMenu";

export default function MainPage() {

    const dispatch = useDispatch();
    const currentPage = useAppSelector(state => state.application.currentPage);
    const user = useUser();
    const currentCustomDashboard = useAppSelector(state => state.application.currentDashboard);
    const [reporting, setReporting] = useState<IReporting>();
    const [reportDef, setReportDef] = useState<IReportDefinition | null>(null);
    const [refresh, setRefresh] = useState<Date>(new Date());
    const refreshDashboards = useCallback(() => setRefresh(new Date()), []);
    useCustomHeaders();

    const [yourDashboards, setYourDashboards] = useState<DashboardGizmo[]>([]);

    useMemo(() => {
        console.log(refresh);
        if (user.role === "editor" || user.role === "administrator") {
            const requestOptions: RequestInit = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "x-ic3-user-id": String(user.id),
                    "x-ic3-role": 'administrator',
                    "x-ic3-locale": "en_US"
                },
                body: JSON.stringify({
                    type: "DASHBOARD",
                    path: "myDrive:"
                })
            };
            fetch('/icCube/api/console/admin/DocsListGizmos', requestOptions)
                .then(response => response.json())
                .then(d => {
                    setYourDashboards(d?.['payload']?.['gizmos'] ?? []);
                })
                .catch(() => {
                    console.info("DocsListGizmos not working");
                });
        }
    }, [refresh, user.role, user.id]);

    const isAdmin = user.role === "administrator";
    const canEdit = (isAdmin || user.role === "editor");

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Box display={"flex"} height={'100vh'}>
            <Header currentPage={currentPage}/>
            <Drawer
                variant="permanent"
                sx={{
                    position: {
                        xs: 'fixed', md: 'sticky',
                    },
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                        md: 'none',
                    },
                    height: '100dvh',
                    width: 240,
                    zIndex: 10,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: 240, boxSizing: 'border-box'},
                    transition: 'transform 0.4s, width 0.4s',
                }}
            >
                <Toolbar variant={'dense'}/>
                <Box
                    className="Sidebar-overlay"
                    sx={{
                        position: 'fixed',
                        zIndex: 9998,
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        opacity: '1',
                        backgroundColor: 'var(--joy-palette-background-backdrop)',
                        transition: 'opacity 0.4s',
                        transform: {
                            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * 240px))',
                            lg: 'translateX(-100%)',
                        },
                    }}
                    onClick={() => closeSidebar()}
                />
                <Box sx={{overflow: 'auto'}} display={'flex'} flexDirection={'column'} height={'100%'}>
                    <List>
                        {
                            Pages.map(i => (
                                <ListItem key={i.name} disablePadding>
                                    <ListItemButton onClick={() => dispatch(setPage(i.name))}
                                                    selected={i.name === currentPage}>
                                        <ListItemIcon>
                                            {i.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={i.name}/>
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                    </List>
                    <Divider/>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Dashboards
                            </ListSubheader>
                        }>
                        {
                            Dashboards.map(i => (
                                <ListItem key={i.name} disablePadding>
                                    <ListItemButton onClick={() => {
                                        dispatch(setPage(i.name));
                                    }} selected={i.name === currentPage}>
                                        <ListItemIcon>
                                            {i.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={i.name}/>
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                        {
                            isAdmin && <ListItem key={"Admin"} disablePadding>
                                <ListItemButton onClick={() => dispatch(setPage("Admin"))}
                                                selected={"Admin" === currentPage}>
                                    <ListItemIcon>
                                        <AdminPanelSettingsIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={"Admin"}/>
                                </ListItemButton>
                            </ListItem>
                        }
                        <ListItemButton onClick={handleClick}>
                            <ListItemText primary="More dashboards"/>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    AiDashboards.map(i => (
                                        <ListItem key={i.name} disablePadding>
                                            <ListItemButton onClick={() => {
                                                dispatch(setPage(i.name));
                                            }} selected={i.name === currentPage} sx={{pl: 4}}>
                                                <ListItemIcon>
                                                    {i.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={i.name}/>
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Collapse>
                    </List>
                    {
                        canEdit && <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader" sx={{
                                    display: 'flex',
                                    flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
                                }}>
                                    Your dashboards
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={refreshDashboards}
                                    >
                                        <RefreshIcon fontSize={'small'}/>
                                    </IconButton>
                                </ListSubheader>
                            }>
                            {
                                yourDashboards.map(i => (
                                    <ListItem key={i.name} disablePadding
                                              secondaryAction={
                                                  <DeleteDashboardMenu path={i} onSuccess={refreshDashboards}/>
                                              }>
                                        <ListItemButton onClick={() => {
                                            dispatch(setPage("CustomDashboard"));
                                            dispatch(setDashboard(i));
                                        }}
                                                        selected={currentPage === "CustomDashboard" && i.path === currentCustomDashboard?.path}>
                                            <ListItemIcon>
                                                <AssessmentRoundedIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={i.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            }
                        </List>
                    }
                    <Box flex={1}/>
                    <Divider/>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <HelpIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Info"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LanguageIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Language"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => dispatch(setIsLoggedIn(false))}>
                                <ListItemIcon>
                                    <LogoutIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logout"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component={"main"} p={0} flex={1} width={"calc(100% - 240px);"} display={'flex'}
                 flexDirection={'column'} overflow={'hidden'}>
                <Toolbar variant={'dense'}/>
                <TopDashboardEditorBar reporting={reporting} reportDef={reportDef}/>
                <PopupOrderDetail/>
                <PageController setReportDef={setReportDef} setReporting={setReporting} reportDef={reportDef}/>
            </Box>
        </Box>
    );
}


export interface DashboardGizmo {
    name: string;
    path: string;
}