import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, {useCallback} from "react";
import {Menu, MenuItem} from "@mui/material";
import {DashboardGizmo} from "../MainPage";
import useUser from "../hooks/useUser";

interface Props {
    path: DashboardGizmo;
    onSuccess: () => void;
}

export default function DeleteDashboardMenu(props: Props) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const user = useUser();

    const deleteDashboard = useCallback((path: DashboardGizmo) => {
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "x-ic3-user-id": String(user.id),
                "x-ic3-role": 'administrator',
                "x-ic3-locale": "en_US"
            },
            body: JSON.stringify({
                version: "8.0.0",
                action: "deleteGizmo",
                type: "REPORT",
                path: path.path
            })
        };
        fetch('/icCube/gvi', requestOptions)
            .then(() => props.onSuccess())
            .catch(error => console.error("Delete dashboard error: " + error));
    }, [user]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDeleteClick = () => {
        deleteDashboard(props.path);
        setAnchorEl(null);
    };

    return <div>
        <IconButton edge="end" aria-label="delete" size={"small"} onClick={handleClick}>
            <MoreVertIcon fontSize={"small"} sx={{color: 'text.secondary'}}/>
        </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleDeleteClick}>Delete Dashboard</MenuItem>
        </Menu>
    </div>
}