import React, {useCallback} from 'react';
import {IReporting} from '@ic3/reporting-api-embedded';
import {IReportParam} from "@ic3/reporting-api-embedded/dist/IReporting";
import {EmbeddedDiv} from "./EmbeddedDiv";

interface IDashboardsDiv {

    uid: string;

    className?: string;

    autoResize?: boolean;
    dashboardPath: string;
    params?: IReportParam[];
    setReporting: (ic3: IReporting) => void;
}


export function DashboardsDiv(props: IDashboardsDiv) {

    const {
        uid, className, autoResize, dashboardPath, params,
        setReporting
    } = props;

    const ic3ready = useCallback((ic3: IReporting) => {
        ic3.openReport({
            path: dashboardPath,
            params: params
        });
        setReporting(ic3);
    }, [dashboardPath, params, setReporting]);

    return <EmbeddedDiv uid={uid} autoResize={autoResize} onIc3ready={ic3ready} className={className}/>

}